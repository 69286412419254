<template>
  <div class="home">
  <el-container>
    <el-header height="200" style="padding: 0;">
      <Nav :showSearch="false"/>
    </el-header>
    <div class="wrapper">
      <div id="building"
           style="align-self:center; text-align: center ;border-radius: 10px">
        <div class="title">选择注册账号类型</div>
        <div class="describe">请选择以下任意一种类型完成网站注册</div>
        <div>
          <div class="link_a">
            <router-link to="/register_user">
           <div>
             <img :src=i_user  class="header-icon"/>
           </div>
            <div>
              个人用户注册
            </div>
            </router-link>
          </div>
          <div class="link_a">
            <router-link to="/register_enterprise">
              <div >
                <img :src=i_enterprise  class="header-icon"/>
              </div>
              <div>
                企业用户注册
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div style="position:absolute;bottom: 0px; width: 100%; ">
      <footer2/>
    </div>
  </el-container>
  </div>
</template>
<script setup>
import Nav from "@/components/nav";
import i_enterprise from "@/assets/img/ico-enterprise.png";
import i_user from "@/assets/img/ico-user.png";

import footer2 from "components/footer.vue";
import {reactive, ref} from "vue";
const loginFormRef = ref();
const form = reactive({
  username: "",
  password: "",
  verifyCode: "",
  userPhone: "",

})
</script>

<style lang="scss" scoped>

.wrapper {
  /*渐变的背景色*/
  /*height: 100vh;
  background-image: linear-gradient(to bottom right, #FC466B, #3F5EF8);
  overflow: hidden;*/
  /*背景图*/
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.title {
  text-align: center;
  font-size: 25px;
  color: #3f3f3f;
  margin-bottom: 10px;
}
.describe {
  text-align: center;
  color: #3f3f3f;
  margin-bottom: 30px;
}
.header-icon {
  margin-top: 12px;
  width: 65px;
  height: 65px;
}
.link_a{
  padding: 50px 60px; float: left; border: 1px solid #f0f1f3; margin-right:28px;
  box-shadow:5px 10px  10px #dfdfdf;
}
</style>
